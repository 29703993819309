import { render, staticRenderFns } from "./SearchDrugs.vue?vue&type=template&id=0054afdc&scoped=true&"
import script from "./SearchDrugs.vue?vue&type=script&lang=js&"
export * from "./SearchDrugs.vue?vue&type=script&lang=js&"
import style0 from "./SearchDrugs.vue?vue&type=style&index=0&id=0054afdc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0054afdc",
  null
  
)

export default component.exports