// src/middleware/auth.js

export default function authMiddleware(to, from, next) {
    // Retrieve and parse the user token data from localStorage
    const userTokenData = JSON.parse(localStorage.getItem('userTokenData') || '{}');

    // Check if user is authenticated and belongs to the 'manager' group
    const isAuthenticated = userTokenData?.user?.groups?.[0]?.name === 'manager';

    if (isAuthenticated) {
        next(); // Allow access
    } else {
        next('/login'); // Redirect to login if not authenticated
    }
}
