/* eslint-disable no-unused-vars */
const config = {
  serverUrl: "https://api2.gopharm.uz/api",
  socketNotifiUri: "wss://api.gopharm.uz/ws/notifications?token=",
  socketMessageUri: "wss://api.gopharm.uz/ws/messages?token=",
  socketDriversUri: "wss://api.gopharm.uz/ws/drivers?token=",
  yandexApi: "3226911d-3db0-4fda-ba6e-2aace95b916e",
};

const debug = {
  // serverUrl: "https://dev.gopharm.obuna.com/api",
  serverUrl: "http://127.0.0.1:8000/api",
  // socketNotifiUri: "wss://test.gopharm.uz/ws/notifications?token=",
  // socketMessageUri: "wss://test.gopharm.uz/ws/messages?token=",
  // socketDriversUri: "wss://test.gopharm.uz/ws/drivers?token=",
  yandexApi: "3226911d-3db0-4fda-ba6e-2aace95b916e",
};

const isPro = process.env.PROD == "production";
// export default debug;
export default config;
// export default isPro ? config : debug;
