<script>
import LogoutAlert from "../components/Modals/LogoutAlert.vue";

export default {
  components: {
    LogoutAlert,
  },

  data: () => ({
    isLogoutAlert: false,
  }),

  watch: {
    "$i18n.locale"(v) {
      this.$bStore.setLocal("lang", v);
    },
  },

  computed: {
    orderToAdd: (vm) => vm.$store.getters.getOrderToAdd,
    newMessageNotifis: (vm) => vm.$store.getters.getNewMessageNotifis,
    user: (vm) => vm.$store.getters.getUser,
    getFullName() {
      let name = this.user.user.first_name;
      let lastName = this.user.user.last_name;

      if (window.outerWidth < 840) return null;
      if (!name?.trim()) return null;

      return `${name}${lastName ? ` ${lastName}` : ""}`;
    },
  },

  mounted() {
    if (this.orderToAdd.drugs.length == 0) {
      this.$router.go(-1);
    }
  },

  methods: {
    onThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$bStore.setLocal("$vuetify.theme.dark", this.$vuetify.theme.dark);
    },

    getTotal() {
      let drugs = this.orderToAdd.drugs.map((x) => x.qty * x._price);
      return drugs.reduce((a, b) => a + b, 0);
    },

    isAcceptReport() {
      return this.user.user.groups.some(
        (group) => group.name === "manager" || group.name === "store"
      );
    },

    onRemoveSelectedDrug(drugId) {
      this.$store.dispatch("removeSelectedDrug", drugId);
    },

    onToOrder(type) {
      let url = type == "shipping" ? "/cartForWhom" : "/whoWillTake";

      let orderToAdd = { ...this.orderToAdd };
      orderToAdd.type = type;
      this.$store.dispatch("setOrderToAdd", orderToAdd);

      setTimeout(() => {
        this.$router.push(url);
      }, 1);
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? '' : 'primary'"
      elevation="3"
      app
      clipped-left
      dark
    >
      <router-link
        tag="span"
        to="/home"
        class="c-pointer title d-flex ml-3 mr-10"
      >
        <img src="/img/icons/favicon-32x32.png" alt="s" />
        <span class="pl-3 logo-name">GoPharm</span>
      </router-link>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
      <div @click="$router.replace('/searchDrugs')" class="py-1">
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndUp"
          rounded
          readonly
          append-icon="mdi-magnify"
          flat
          dense
          hide-details
          :label="$t('Search for drugs...')"
          solo-inverted
        />
        <v-btn v-else dark icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-btn to="/ordersStore" text>
        {{ $t("Orders") }}
      </v-btn>
      <v-btn to="/report" v-if="isAcceptReport()" text>
        {{ $t("Report") }}
        </v-btn>
      <v-badge
        :content="orderToAdd.drugs.length"
        :value="orderToAdd.drugs.length"
        color="red"
        overlap
        offset-x="20"
        offset-y="20"
      >
        <v-btn class="ml-2" to="/cart" icon>
          <v-icon> mdi-cart </v-icon>
        </v-btn>
      </v-badge>
      <v-badge
        :content="newMessageNotifis.length"
        :value="newMessageNotifis.length"
        color="red"
        overlap
        offset-x="20"
        offset-y="20"
      >
        <v-btn to="/chat" icon class="ml-2">
          <v-icon> mdi-email </v-icon>
        </v-btn>
      </v-badge>
      <v-menu min-width="260">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :icon="!getFullName"
            :color="getFullName ? 'transparent' : ''"
            v-bind="attrs"
            v-on="menu"
            class="ml-2"
          >
            <v-icon>mdi-account-cog</v-icon>
            <template v-if="getFullName">
              <span class="pl-2" v-text="getFullName" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.theme.dark">
            <v-list-item @click="onThemeChange" :value="true">
              <v-list-item-avatar>
                <v-icon v-if="$vuetify.theme.dark">
                  mdi-white-balance-sunny
                </v-icon>
                <v-icon v-else> mdi-weather-night </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("darkMode") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-divider />

          <v-subheader>{{ $t("languageOfApp") }}</v-subheader>
          <v-list-item-group v-model="$i18n.locale">
            <v-list-item value="uz">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/uz.png" />
              </v-list-item-avatar>
              <v-list-item-title>O'zbek tili</v-list-item-title>
            </v-list-item>
            <v-list-item value="ru">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/ru.png" />
              </v-list-item-avatar>
              <v-list-item-title>Русский язык</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item @click="isLogoutAlert = true">
            <v-list-item-avatar>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <LogoutAlert v-model="isLogoutAlert" />

    <v-main style="padding-top: 85px">
      <v-container class="pt-5">
        <v-row justify="center">
          <v-col cols="12" md="5" xl="3">
            <v-card min-height="250">
              <div class="w-100">
                <v-card-title>
                  {{ $t("Cart") }}
                </v-card-title>
                <v-list three-line>
                  <v-list-item
                    v-for="(item, index) in orderToAdd.drugs"
                    :key="index"
                    @click="() => {}"
                  >
                    <v-list-item-avatar
                      @click="$tools.openImg(item._image_thumbnail)"
                    >
                      <v-img
                        lazy-src="/media/drugs-thumbnail/default.png"
                        :src="item._image_thumbnail"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item._name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("Count") }}:
                        {{ item.qty | sum }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ $t("Price") }}:
                        {{ item._price }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        x-large
                        @click="onRemoveSelectedDrug(item.drug)"
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>

              <div class="w-100 pb-3 px-4 pt-3">
                <v-divider />
                <div class="text-right pt-4 px-2">
                  {{ $t("Total") }}:
                  <span class="fw-500 pl-2 pr-1">{{ getTotal() | sum }}</span>
                  {{ $t("sum") }}
                </div>

                <div class="pt-4">
                  <v-btn
                    @click="onToOrder('shipping')"
                    block
                    dark
                    color="primary"
                  >
                    {{ $t("To order delivery") }}
                  </v-btn>
                </div>
                <div class="pt-4">
                  <v-btn @click="onToOrder('self')" block dark color="primary">
                    {{ $t("Take with you") }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<style scoped>
.theme--light .card-text {
  color: black;
}

@media only screen and (max-width: 410px) {
  .logo-name {
    display: none;
  }
}

.v-card.v-sheet {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
</style>
