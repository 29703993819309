<template>
  <v-container fluid>
    <!-- Filters Section -->
    <div class="d-flex justify-space-between align-center mb-4 px-4">
      <div class="d-flex align-center">
        <!-- Date Range Pickers -->
        <div style="width: 200px" class="mr-4">
          <MyDatePicker
            v-model="dateRange.from"
            @input="loadData"
            :label="$t('from')"
          />
        </div>
        <div style="width: 200px">
          <MyDatePicker
            v-model="dateRange.until"
            @input="loadData"
            :label="$t('to')"
          />
        </div>

        <!-- Store Selector for Managers -->
        <div v-if="isManager()" style="width: 400px" class="ml-4">
          <label>Выбрать аптеку</label>
          <v-select
            v-model="selectedStore"
            :items="storesWithAll"
            item-text="name"
            item-value="id"
            :label="$t('Pharmacy')"
            outlined
            dense
            @change="loadData"
          />
        </div>
      </div>
    </div>

    <!-- Summary Table -->
    <v-data-table
      :headers="headers"
      :items="formatItems"
      :loading="loading"
      :items-per-page="daysInCurrentMonth"
      :footer-props="{
        'items-per-page-options': [daysInCurrentMonth, 60, 90],
        'items-per-page-text': 'Строк на странице',
        'page-text': '{0}-{1} из {2}',
      }"
      :hide-default-footer="false"
      :hide-default-header="true"
    >
      <!-- Custom Header -->
      <template v-slot:header>
        <thead>
          <tr>
            <th rowspan="2" class="text-left">Дата</th>
            <th rowspan="2" class="text-left">Аптека</th>
            <th colspan="3" class="text-center">Кол-во заказов</th>
            <th colspan="3" class="text-center">Сумма</th>
          </tr>
          <tr>
            <th class="text-right">Нал</th>
            <th class="text-right">Онлайн</th>
            <th class="text-right">Итого</th>
            <th class="text-right">Нал</th>
            <th class="text-right">Онлайн</th>
            <th class="text-right">Итого</th>
          </tr>
        </thead>
      </template>

      <!-- Table Body -->
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.date }}</td>
          <td>{{ item.store_id }}</td>
          <td class="text-right">{{ item.orders.cash }}</td>
          <td class="text-right">{{ item.orders.online }}</td>
          <td class="text-right">{{ item.orders.total }}</td>
          <td class="text-right">{{ formatCurrency(item.amounts.cash) }}</td>
          <td class="text-right">{{ formatCurrency(item.amounts.online) }}</td>
          <td class="text-right">{{ formatCurrency(item.amounts.total) }}</td>
        </tr>
      </template>
    </v-data-table>
    <br /><br /><br />
    <v-data-table
      :headers="headers"
      :items="totals"
      :loading="loading"
      :hide-default-footer="true"
      :hide-default-header="true"
    >
      <!-- Custom Header -->
      <template v-slot:header>
        <thead>
          <tr>
            <th colspan="3" class="text-center">
              Количество заказов с {{ formatDate(dateRange.from) }} по
              {{ formatDate(dateRange.until) }}
            </th>
            <th colspan="3" class="text-center">
              Сумма заказов с {{ formatDate(dateRange.from) }} по
              {{ formatDate(dateRange.until) }}
            </th>
          </tr>
          <tr>
            <th class="text-right">Нал</th>
            <th class="text-right">Онлайн</th>
            <th class="text-right">Итого</th>
            <th class="text-right">Нал</th>
            <th class="text-right">Онлайн</th>
            <th class="text-right">Итого</th>
          </tr>
        </thead>
      </template>

      <!-- Table Body -->
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-right font-weight-bold">{{ item?.orders.cash }}</td>
          <td class="text-right font-weight-bold">
            {{ item?.orders.online }}
          </td>
          <td class="text-right font-weight-bold">
            {{ item?.orders.total }}
          </td>
          <td class="text-right font-weight-bold">
            {{ formatCurrency(item?.amounts.cash) }}
          </td>
          <td class="text-right font-weight-bold">
            {{ formatCurrency(item?.amounts.online) }}
          </td>
          <td class="text-right font-weight-bold">
            {{ formatCurrency(item?.amounts.total) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import MyDatePicker from "../components/MyDatePicker.vue";

export default {
  name: "OrderSummary",

  components: {
    MyDatePicker,
  },

  data: () => ({
    loading: false,
    items: [],
    totals: [],
    dateRange: {
      from: (() => {
        // Get first day of current month
        const date = new Date();
        date.setDate(1); // Set to first day of month
        return date.toISOString().substr(0, 10);
      })(),
      until: new Date().toISOString().substr(0, 10), // Today
    },
    selectedStore: null,
    stores: [],
    headers: [
      { text: "Дата", value: "date", width: "120px" },
      { text: "Аптека", value: "store_id", width: "100px" },
      {
        text: "Нал",
        value: "orders.cash",
        width: "100px",
        align: "right",
      },
      {
        text: "Онлайн",
        value: "orders.online",
        width: "100px",
        align: "right",
      },
      {
        text: "Итого",
        value: "orders.total",
        width: "100px",
        align: "right",
      },
      {
        text: "Нал",
        value: "amounts.cash",
        width: "150px",
        align: "right",
      },
      {
        text: "Онлайн",
        value: "amounts.online",
        width: "150px",
        align: "right",
      },
      {
        text: "Итого",
        value: "amounts.total",
        width: "150px",
        align: "right",
      },
    ],
  }),

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    formatItems() {
      return this.items.map((item) => ({
        ...item,
        date: item.date,
      }));
    },
    storesWithAll() {
      return [{ id: null, name: "Все аптеки" }, ...this.stores];
    },
    daysInCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    },
  },

  mounted() {
    this.loadStores();
    this.loadData();
  },

  methods: {
    isManager() {
      return !!this.user.user.groups.find((x) => x.name == "manager");
    },
    async loadStores() {
      if (this.isManager()) {
        try {
          const response = await this.axios.get("v1/stores");
          this.stores = response.data;
        } catch (error) {
          console.error("Error loading stores:", error);
        }
      }
    },
    formatDate(date) {
      if (!date) return "";

      const dateObj = typeof date === "string" ? new Date(date) : date;

      if (isNaN(dateObj.getTime())) {
        console.error("Invalid date:", date);
        return "";
      }

      const addZero = (n) => (n < 10 ? `0${n}` : n);

      const day = addZero(dateObj.getDate());
      const month = addZero(dateObj.getMonth() + 1);
      const year = dateObj.getFullYear();

      return `${day}.${month}.${year}`;
    },

    async loadData() {
      this.loading = true;
      try {
        const response = await this.axios.post("v2/stores/report", {
          from: this.dateRange.from,
          until: this.dateRange.until,
          store_id: this.selectedStore ?? this.user.user.store_id,
        });

        if (response.data.status === "success") {
          this.items = response.data.data.orders;
          this.totals = response.data.data.totals;
        }
      } catch (error) {
        console.error("Error loading order summary:", error);
      } finally {
        this.loading = false;
      }
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("ru-RU", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-data-table {
  &::v-deep {
    .v-data-table__wrapper {
      overflow-x: auto;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th,
    td {
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 8px 16px !important;
      height: 48px;
      white-space: nowrap;
    }

    th {
      background-color: #f5f5f5 !important;
      font-weight: bold !important;
    }

    .total-row {
      background-color: #f5f5f5;
      td {
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.12);
        &:first-child {
          // Style for "Итого за период:" cell
          color: #000; // Makes the text clearly visible
          font-size: 14px; // Match the font size in the image
        }
      }
    }
  }
}

.text-right {
  text-align: right !important;
}
</style>
