<script>
import { debounce } from "debounce";

import MyTimer from "../components/MyTimer.vue";

import OrderChangeStore from "../components/Modals/OrderChangeStore.vue";
import OrderLocation from "../components/Modals/OrderLocation.vue";
import EditStoreOrder from "../components/Modals/EditStoreOrder.vue";
import MyDatePicker from "../components/MyDatePicker.vue";
import ImageModal from "@/components/Modals/ImageModal.vue";
export default {
  components: {
    OrderChangeStore,
    OrderLocation,
    EditStoreOrder,
    MyTimer,
    MyDatePicker,
    ImageModal,
  },

  data: () => ({
    orderChangeStoreDialog: false,

    tbHeight: 100,
    loading: false,
    selectedOrder: {},
    isModalOpen: false,
    modalImageUrl: "",

    tbOrderDetailHeaders: [],
    paymentHeaders: [],
    tbOrderHeaders: [],

    options: {},
    itemsPerPage: 50,

    sheet: false,

    orderTypes: [],

    orderStatuses: [],
    paymentTypes: [],
    stores: [],
    sortByStatus: "",
    sortByPay: "",
    orderType: "",
    created_at__gte: "",
    created_at__lte: "",
    search: "",
    store: "",
    isOrderLocation: false,
    isEditStoreOrder: false,
  }),

  computed: {
    user: (vm) => vm.$store.getters.getUser,

    orders: (vm) => vm.$store.getters.getOrders,
    totalPages: (vm) => vm.$store.getters.getTotalOrdersCount,

    getCoords() {
      const coords = this.selectedOrder.location.coordinates;
      const [lon, lat] = coords;
      return [lat, lon];
    },

    isManager: (vm) => !!vm.user.user.groups.find((x) => x.name == "manager") || !!vm.user.user.groups.find((x) => x.name == "operator"),
  },

  watch: {
    options: {
      handler() {
        this.getOrders();
      },
      deep: true,
    },

    "$i18n.locale"() {
      this.onChangeLang();
    },
  },

  mounted() {
    if (this.isManager) {
      this.axios.get("v1/stores").then((res) => {
        let stores = res.data;
        stores.unshift({
          id: "",
          name: this.$t("all"),
        });
        this.stores = stores;
      });
    }

    this.getOrders();
    this.onChangeLang();
  },

  methods: {
    getCash(order) {
      let delivery = Number(order?.delivery_total) || 0;
      let total = delivery + (Number(order?.real_total) || 0);
      return total - (Number(order?.cash) || 0);
    },

    onResize: debounce(function () {
      this.tbHeight = window.innerHeight - 220;
    }, 500),

    getOrders() {
      if (this.loading) return;

      this.getOrdersApi().then((data) => {
        this.selectedOrder = isArr(data.items) ? data.items[0] : {};
      });

      function isArr(arr) {
        return Array.isArray(arr) && arr.length;
      }
    },

    getShipType(type) {
      let e = this.orderTypes.find((x) => x.value == type);
      return e ? e.name : "";
    },

    getOrdersApi() {
      this.loading = true;
      this.options.status = this.sortByStatus;
      this.options.type = this.orderType;
      this.options.payment_type = this.sortByPay;
      this.options.store = this.store;
      this.options.created_at__gte = this.created_at__gte;
      this.options.created_at__lte = this.created_at__lte;
      return this.$store.dispatch("getOrdersApi", this.options).finally(() => {
        this.loading = false;
      });
    },

    getSearch() {
      setTimeout(() => {
        this.options.search = this.search;
        this.getOrders();
      }, 1);
    },

    onSelectOrder(order) {
      this.selectedOrder = order;
    },

    async onAccept(order) {
      this.loading = true;
      await this.$store.dispatch("orderAccept", order);
      this.loading = false;
    },

    async onOrderPickedUp(order) {
      this.loading = true;
      await this.$store.dispatch("orderPickedUp", order);
      this.loading = false;
    },

    onChangedStore() {
      this.sheet = false;
      this.selectedOrder = {};
    },

    getPayType(item) {
      if (!item) return "";
      if (!this.paymentTypes.length) return item.name;
      let type = this.paymentTypes.find((x) => x.id == item.id);
      return type ? type.pan : "";
    },

    getPayTypeColor(item) {
      if (!item) return "";
      switch (item.type) {
        case "online":
          return "#2E7D32";

        case "cash":
          return "red";
      }
    },

    getOrderStore(item) {
      if (!item) return "";

      return item.name;
    },

    onOpenSheet(item) {
      if (item.items.length) {
        this.sheet = !this.sheet;
      } else {
        this.sheet = false;
      }
    },

    copy(item) {
      return JSON.parse(JSON.stringify(item));
    },

    onChangeLang() {
      const vm = this;

      this.orderTypes = [
        {
          name: vm.$t("all"),
          value: "",
        },
        {
          name: vm.$t("Shipping"),
          value: "shipping",
        },
        {
          name: vm.$t("Pickup"),
          value: "self",
        },
      ];

      let tbOrderHeaders = [
        {
          text: "",
          value: "actions",
          width: 2,
          sortable: false,
        },
        {
          text: "№",
          value: "id",
          width: 2,
        },
        {
          text: vm.$t("status"),
          value: "status",
          width: 2,
        },
        {
          text: "",
          value: "defective",
          width: 2,
          isManager: true,
        },
        {
          text: vm.$t("FIO"),
          value: "full_name",
          width: 180,
        },
        {
          text: vm.$t("phone"),
          value: "phone",
          width: 130,
        },
        {
          text: vm.$t("Summa"),
          value: "real_total",
          width: 2,
        },
        // {
        //   text: "Cashback",
        //   value: "cash",
        //   width: 2,
        // },
        {
          text: "Real Summa",
          value: "real_total2",
          width: 2,
        },
        {
          text: vm.$t("Shipping amount"),
          value: "delivery_total",
          width: 110,
        },
        {
          text: vm.$t("Order date"),
          value: "created_at",
          width: 130,
        },
        {
          text: vm.$t("The pharmacy accepted"),
          value: "pharmacy_accepted",
          width: 120,
          isManager: true,
        },
        {
          text: vm.$t("Deliverer accepted"),
          value: "deliverer_accepted_time",
          width: 120,
          isManager: true,
        },
        {
          text: vm.$t("Time of delivery"),
          value: "finished_shiptime",
          width: 120,
        },
        {
          text: vm.$t("Time has passed"),
          value: "timer",
          width: 100,
        },
        {
          text: vm.$t("Payment type"),
          value: "payment_type",
          width: 100,
        },
        {
          text: vm.$t("Pharmacy address"),
          value: "store",
          width: 120,
          isManager: true,
        },
        {
          text: vm.$t("address"),
          value: "address",
        },
      ];

      let tbOrderDetailHeaders = [
        {
          text: "",
          value: "image",
          width: 32,
          sortable: false,
        },
        {
          text: vm.$t("name"),
          value: "name",
        },
        {
          text: vm.$t("Manufacturer"),
          value: "manufacturer",
        },
        {
          text: vm.$t("Pharmacy"),
          value: "store",
          //isManager: true,
        },
        {
          text: vm.$t("quantity"),
          value: "qty",
          width: 20,
          sortable: false,
        },
        {
          text: vm.$t("priceForOne"),
          value: "price",
          width: 120,
          sortable: false,
        },
        {
          text: vm.$t("totalAmount"),
          value: "subtotal",
          width: 120,
          sortable: false,
        },
      ];

      let paymentHeaders = [
        {
          text: "Время",
          value: "time",
        },
        {
          text: "Действие",
          value: "activity",
        },
        {
          text: "ID",
          value: "id",
        },
        {
          text: vm.$t("Price"),
          value: "price",
        },
      ];

      let orderStatuses = [
        {
          name: "",
          description: this.$t("all"),
        },
        {
          name: "not_paid",
          description: vm.$t("Not paid"),
          icon: "mdi-cash-remove",
          color: "green",
          isManager: true,
        },
        {
          name: "payment_waiting",
          description: this.$t("Payment is waiting"),
          icon: "mdi-cash-remove",
          color: "pink",
          isManager: true,
        },
        {
          name: "pending",
          description: this.$t("Pending"),
          icon: "mdi-clock-outline",
          color: "yellow",
        },
        {
          name: "accept",
          description: this.$t("Accept"),
          icon: "mdi-storefront-outline",
          color: "#43A047",
        },
        {
          name: "cancelled_by_store",
          description: this.$t("Cancelled by store"),
          icon: "mdi-sticker-alert-outline",
          color: "#E53935",
        },
        {
          name: "waiting_deliverer",
          description: this.$t("Waiting for deliverer"),
          icon: "mdi-bus-clock",
          color: "#0288D1",
        },
        {
          name: "delivering",
          description: this.$t("Delivering"),
          icon: "mdi-truck-fast-outline",
          color: "#0288D1",
        },
        {
          name: "delivered",
          description: this.$t("Delivered"),
          icon: "mdi-check-all",
          color: "green",
        },
        {
          name: "cancelled_by_admin",
          description: this.$t("Cancel by admin"),
          icon: "mdi-delete-outline",
          color: "#616161",
        },
        {
          name: "pick_up",
          description: this.$t("Pickup"),
          icon: "mdi-human-greeting",
          color: "#B39DDB",
        },
        {
          name: "picked_up",
          description: this.$t("Picked up"),
          icon: "mdi-check-all",
          color: "#4CAF50",
        },
        {
          name: "cancelled_by_user",
          description: this.$t("Cancelled by user"),
          icon: "mdi-delete-outline",
          color: "#616161",
        },
        {
          name: "waiting_for_assembly",
          description: this.$t("Waiting for assembly"),
          icon: "mdi-clock-outline",
          color: "gray",
        },
        {
          name: "accepted_by_the_pharmacist",
          description: this.$t("Accepted by the pharmacist"),
          icon: "mdi-clock-outline",
          color: "success",
        },
        {
          name: "assembled",
          description: this.$t("Assembled"),
          icon: "mdi-check-all",
          color: "success",
        },
      ];

      if (this.isManager) {
        this.orderStatuses = orderStatuses;
        this.tbOrderHeaders = tbOrderHeaders;
        this.tbOrderDetailHeaders = tbOrderDetailHeaders;
      } else {
        this.orderStatuses = orderStatuses.filter((x) => !x.isManager);
        this.tbOrderHeaders = tbOrderHeaders.filter((x) => !x.isManager);
        this.tbOrderDetailHeaders = tbOrderDetailHeaders.filter(
          (x) => !x.isManager
        );
      }
      this.paymentHeaders = paymentHeaders;

      this.axios
        .get("v1/order-options?lan=" + this.$i18n.locale)
        .then((res) => {
          this.paymentTypes = getPayTypes(res.data.payment_types, this);

          function getPayTypes(types, vm) {
            if (Array.isArray(types)) {
              let ts = types.map((x) => {
                if (x.name == "new") x.pan = vm.$t("Online");
                return x;
              });

              ts.unshift({
                id: "",
                pan: vm.$t("all"),
              });

              return ts;
            }
            return [];
          }
        });
    },

    openImg(url) {
      if (url) {
        this.modalImageUrl = url;
        this.isModalOpen = true;
      }
    },

    onEditStoreOrderSaved() {
      this.sheet = false;
      this.getOrders();
    },

    onEditStoreOrder(item) {
      this.selectedOrder = item;
      this.$nextTick(() => {
        this.isEditStoreOrder = true;
      });
    },

    getDrugImg(url) {
      let defImg = require("../assets/img/svg/place_holder.svg");
      if (!url || (url && !url.trim())) return defImg;
      if (url.includes("default.png")) return defImg;

      return url;
    },

    getOrderStatus(status) {
      let oStatus = this.orderStatuses.find((x) => x.name == status);
      return oStatus ? oStatus.icon : "";
    },

    getOrderStatusDecs(status) {
      let oStatus = this.orderStatuses.find((x) => x.name == status);
      return oStatus ? oStatus.description : "";
    },

    getOrderStatusColor(status) {
      let oStatus = this.orderStatuses.find((x) => x.name == status);
      return oStatus ? oStatus.color : "";
    },

    isAcceptBtn(status) {
      return status == "pending";
    },

    isCancelBtn(status) {
      return status == "pending";
    },

    isPickUp(status) {
      return status == "pick_up";
    },

    getManufacturer(drug) {
      return drug?.manufacturer?.name;
    },

    getStatus() {
      let orr = this.orderStatuses.find(
        (x) => x.name == this.selectedOrder.status
      );
      return orr ? orr.description : "";
    },

    getStatusBtnsColor(status) {
      if (this.$vuetify.theme.dark) {
        return this.selectedOrder.status == status.name
          ? "rgb(255 255 255 / 82%)"
          : "#272727";
      } else {
        return this.selectedOrder.status == status.name ? "#1976d247" : "white";
      }
    },

    getTimerStatus(status) {
      let st =
        status == "not_paid" ||
        status == "delivered" ||
        status == "cancelled_by_admin" ||
        status == "cancelled_by_user" ||
        status == "cancelled_by_store" ||
        status == "picked_up";

      return !st;
    },

    acceptOrder() {
      this.axios
        .post(`v1/accept-order/${this.selectedOrder.id}`)
        .then((res) => {
          if (res.status) {
            this.getOrdersApi().then((data) => {
              this.selectedOrder = isArr(data.items) ? data.items[0] : {};
            });
          }
          function isArr(arr) {
            return Array.isArray(arr) && arr.length;
          }
        });
    },
    pickedUpOrder() {
      this.axios.post(`v2/picked-up/${this.selectedOrder.id}`).then((res) => {
        if (res.status) {
          this.getOrdersApi().then((data) => {
            this.selectedOrder = isArr(data.items) ? data.items[0] : {};
          });
        }
        function isArr(arr) {
          return Array.isArray(arr) && arr.length;
        }
      });
    },
  },
};
</script>

<template>
  <v-container fluid v-resize="onResize">
    <div class="d-flex px-xl-2 pb-2 top-filters">
      <v-spacer />
      <div style="width: 260px" class="mr-2">
        <v-text-field
          append-icon="mdi-magnify"
          dense
          outlined
          hide-details
          v-model="search"
          @keyup.enter="getSearch"
          @click:append="getSearch"
          @click:clear="getSearch"
          :label="$t('Search...')"
          clearable
        />
      </div>

      <div class="w-200">
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          @change="getOrders"
          prepend-inner-icon="mdi-filter-variant"
          dense
          hide-details
          outlined
          v-model="sortByStatus"
          :label="$t('status')"
          item-value="name"
          item-text="description"
          :items="orderStatuses"
        />
      </div>

      <div class="w-200 ml-3">
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          @change="getOrders"
          prepend-inner-icon="mdi-filter-variant"
          dense
          hide-details
          outlined
          v-model="orderType"
          :label="$t('Order type')"
          item-value="value"
          item-text="name"
          :items="orderTypes"
        />
      </div>

      <div class="w-200 ml-3">
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          @change="getOrders"
          prepend-inner-icon="mdi-filter-variant"
          dense
          hide-details
          outlined
          v-model="sortByPay"
          :label="$t('Payment type')"
          item-value="id"
          item-text="pan"
          :items="paymentTypes"
        />
      </div>

      <div class="w-200 ml-3" v-if="isManager">
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          @change="getOrders"
          prepend-inner-icon="mdi-filter-variant"
          dense
          hide-details
          outlined
          :label="$t('Pharmacy')"
          item-value="id"
          item-text="name"
          :items="stores"
          v-model="store"
        />
      </div>

      <div style="width: 155px" class="ml-3">
        <MyDatePicker
          v-model="created_at__gte"
          @input="getOrders"
          :label="$t('from')"
        />
      </div>

      <div style="width: 155px" class="ml-3">
        <MyDatePicker
          v-model="created_at__lte"
          @input="getOrders"
          :label="$t('to')"
        />
      </div>
    </div>

    <v-data-table
      :mobile-breakpoint="0"
      :height="tbHeight + 20"
      :loading="loading"
      :headers="tbOrderHeaders"
      fixed-header
      :items="orders"
      :options.sync="options"
      :server-items-length="totalPages"
      item-key="id"
      :items-per-page="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="c-pointer"
            @click="onSelectOrder(item)"
            :class="item.id == selectedOrder.id ? 'dt-active' : ''"
            :key="key"
            v-for="(item, key) in props.items"
            @dblclick="onOpenSheet(item)"
          >
            <td v-ripple @click="onOpenSheet(item)" class="pl-2">
              <v-icon :title="$t('detail')" v-if="item.items.length">
                mdi-android-messages
              </v-icon>
            </td>
            <td>
              <span class="text_nowrap">{{ item.id | sum }}</span>
            </td>
            <td class="text-center">
              <v-tooltip color="black" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="
                      getOrderStatusColor(
                        item.payment_status ? item.payment_status : item.status
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      getOrderStatus(
                        item.payment_status ? item.payment_status : item.status
                      )
                    }}
                  </v-icon>
                </template>
                <span>{{
                  getOrderStatusDecs(
                    item.payment_status ? item.payment_status : item.status
                  )
                }}</span>
              </v-tooltip>
            </td>
            <td v-if="isManager" class="text-center">
              <v-icon
                @click="onEditStoreOrder(item)"
                v-if="item.is_defective"
                color="red"
              >
                mdi-content-copy
              </v-icon>
            </td>
            <td>
              <div :title="item.full_name" class="text_nowrap dt-max">
                {{ item.full_name }}
              </div>
            </td>
            <td>
              <span class="text_nowrap">{{ item.phone | tel }}</span>
            </td>
            <td>
              <div class="text_nowrap px-3 fw-500">
                {{ getCash(item) | sum }}
              </div>
            </td>
            <!-- <td>
              <div class="text_nowrap px-3 fw-500">
                {{ item.cash | sum }}
              </div>
            </td> -->
            <td>
              <div class="text_nowrap px-3 fw-500">
                {{ item.real_total | sum }}
              </div>
            </td>
            <td>
              <div class="text_nowrap px-3 fw-500">
                {{ item.delivery_total | sum }}
              </div>
            </td>
            <td>
              <div class="text_nowrap">
                {{ item.created_at | dateTime }}
              </div>
            </td>
            <td v-if="isManager">
              <div class="text_nowrap">
                {{ item.store_accepted_time | dateTime }}
              </div>
            </td>
            <td v-if="isManager">
              <div class="text_nowrap">
                {{ item.deliverer_accepted_time | dateTime }}
              </div>
            </td>
            <td>
              <div class="text_nowrap">
                <MyTimer
                  class="fw-500"
                  v-if="item.type == 'shipping' && item.status == 'delivering'"
                  :startTime="item.start_shiptime"
                />
                <template v-else>
                  {{ item.finished_shiptime | dateTime }}
                </template>
              </div>
            </td>
            <td>
              <div class="text_nowrap">
                <MyTimer
                  class="fw-500"
                  v-if="getTimerStatus(item.status)"
                  :startTime="item.created_at"
                />
                <div v-else>
                  {{ getOrderStatusDecs(item.status) }}
                </div>
              </div>
            </td>
            <td :style="{ color: getPayTypeColor(item.payment_type) }">
              <div class="text_nowrap text-center fw-500">
                {{ getPayType(item.payment_type) }}
              </div>
            </td>
            <td v-if="isManager">
              <div class="text_nowrap dt-max" style="max-width: 200px">
                {{ getOrderStore(item.store) }}
              </div>
            </td>
            <td>
              <div class="text_nowrap">
                {{ item.address }}
              </div>
            </td>
          </tr>
          <tr v-if="!props.items.length">
            <td
              v-if="loading"
              :colspan="tbOrderHeaders.length + 1"
              class="text-center py-4"
            >
              {{ $t("Loading...") }}
            </td>
            <td
              v-else
              :colspan="tbOrderHeaders.length + 1"
              class="text-center py-4"
            >
              {{ $t("No data") }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <EditStoreOrder
      @seved="onEditStoreOrderSaved"
      :selectedOrder="copy(selectedOrder)"
      :getManufacturer="getManufacturer"
      :getDrugImg="getDrugImg"
      v-if="isEditStoreOrder"
      v-model="isEditStoreOrder"
    />

    <v-bottom-sheet v-if="selectedOrder.id" scrollable v-model="sheet">
      <v-card height="90vh" class="d-flex flex-column">
        <v-card-title class="d-flex align-center">
          <div class="fw-500 c-black">
            {{ $t("detailInformations") }}
          </div>
          <v-spacer />
          <v-btn @click="sheet = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="flex-grow-1 overflow-y-auto">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4">
                <div class="order-data px-4">
                  <div class="text_nowrap">
                    <div class="order-data-title">{{ $t("orderNumber") }}:</div>
                    <div class="order-data-text">
                      {{ selectedOrder.id | sum }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">{{ $t("FIO") }}:</div>
                    <div class="order-data-text">
                      {{ selectedOrder.full_name }}
                    </div>
                  </div>
                  <div>
                    <div class="order-data-title">{{ $t("address") }}:</div>
                    <div class="order-data-text">
                      {{ selectedOrder.address }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">{{ $t("phone") }}:</div>
                    <div class="order-data-text">
                      {{ selectedOrder.phone | tel }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">{{ $t("Summa") }}:</div>
                    <div class="order-data-text">
                      {{ getCash(selectedOrder) | sum }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">Real Summa:</div>
                    <div class="order-data-text">
                      {{ selectedOrder.real_total | sum }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">
                      {{ $t("Shipping amount") }}:
                    </div>
                    <div class="order-data-text">
                      {{ selectedOrder.delivery_total | sum }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">
                      {{ $t("Time of delivery") }}:
                    </div>
                    <div class="order-data-text">
                      {{ selectedOrder.finished_shiptime | dateTime }}
                    </div>
                  </div>
                  <div class="text_nowrap">
                    <div class="order-data-title">
                      {{ $t("Payment type") }}:
                    </div>
                    <div class="order-data-text">
                      {{ getPayType(selectedOrder.payment_type) }}
                    </div>
                  </div>
                  <div class="text_nowrap" v-if="selectedOrder.comment">
                    <div class="order-data-title">
                      {{ $t("Additional information") }}:
                    </div>
                    <div class="order-data-text">
                      {{ selectedOrder.comment }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="8" class="text-right pr-sm-10">
                <div class="pb-5" style="overflow-x: auto">
                  <v-btn-toggle v-if="selectedOrder.status" readonly>
                    <v-tooltip
                      v-for="(item, index) in orderStatuses.filter(
                        (x) => !!x.icon
                      )"
                      :key="index"
                      color="black"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :style="{
                            backgroundColor: `${getStatusBtnsColor(
                              item
                            )}!important`,
                          }"
                          class="button-gr"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon :color="item.color">{{ item.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.description }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </div>

                <div class="text_nowrap">
                  <div class="order-data-text pl-0">
                    {{ getShipType(selectedOrder.type) }}
                  </div>
                </div>

                <div v-if="selectedOrder.delivery">
                  <span class="fw-500"> {{ $t("Deliveryman") }}: </span>
                  {{ selectedOrder.delivery.first_name }}
                  <br />
                  {{ selectedOrder.delivery.login | tel }}
                </div>

                <div class="pt-2" v-if="selectedOrder.location">
                  <OrderLocation
                    :item="getCoords"
                    v-if="isOrderLocation"
                    v-model="isOrderLocation"
                  />

                  <v-btn
                    v-if="isManager && selectedOrder.is_defective"
                    class="mr-3"
                    @click="isEditStoreOrder = true"
                    dark
                    color="red"
                  >
                    {{ $t("Edit") }}
                  </v-btn>

                  <v-btn @click="isOrderLocation = true" dark color="primary">
                    {{ $t("To show on the map") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-data-table
              v-if="sheet"
              :headers="tbOrderDetailHeaders"
              :items="selectedOrder.items"
              v-bind="tableConfig"
              :hide-default-footer="true"
            >
              <template v-slot:body="props">
                <ImageModal
                  :isOpen="isModalOpen"
                  :imgUrl="modalImageUrl"
                  @close="isModalOpen = false"
                />
                <tbody>
                  <tr v-for="(item, key) in props.items" :key="key">
                    <td
                      @click="openImg(item.drug.image_thumbnail)"
                      style="width: 100px; text-align: center"
                    >
                      <div>
                        <img
                          :src="getDrugImg(item.drug.image_thumbnail)"
                          :alt="key"
                          style="width: 100px; height: 100px; object-fit: cover"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="text_nowrap">{{ item.drug.name }}</div>
                    </td>
                    <td>
                      <div class="text_nowrap">
                        {{ getManufacturer(item.drug) }}
                      </div>
                    </td>
                    <td>
                      <div class="text_nowrap">
                        {{ getOrderStore(item.store) }}
                      </div>
                    </td>
                    <td>
                      <div class="text_nowrap">{{ item.qty | sum }}</div>
                    </td>
                    <td>
                      <div class="text_nowrap">{{ item.price | sum }}</div>
                    </td>
                    <td>
                      <div class="text_nowrap">{{ item.subtotal | sum }}</div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="5"></td>
                    <td><b>Общая сумма:</b></td>
                    <td>
                      <div class="text_nowrap">
                        <b>{{ selectedOrder.total | sum }}</b>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            <br /><br /><br />
            <v-data-table
              v-if="
                selectedOrder.payment_type.type === 'online' &&
                selectedOrder.items.length > 0
              "
              :headers="paymentHeaders"
              :items="selectedOrder.payments"
              v-bind="tableConfig"
              :hide-default-footer="true"
            >
              <template v-slot:body="props">
                <tbody>
                  <tr v-for="(payment, index) in props.items" :key="index">
                    <td>{{ payment.time | dateTime }}</td>
                    <td>{{ payment.activity }}</td>
                    <td v-if="Array.isArray(payment.id)">
                      <div v-for="(link, index) in payment.id" :key="index">
                        <a
                          :href="link"
                          target="_blank"
                          rel="noopener noreferrer"
                          >{{ `Чек оплаты ${index + 1}` }}</a
                        >
                      </div>
                    </td>
                    <td v-else>{{ payment.id }}</td>
                    <td>{{ payment.time ? payment.price : "" }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-9 py-2">
          <v-spacer />
          <v-btn
            v-if="selectedOrder.status == 'pick_up'"
            @click="pickedUpOrder()"
            class="mr-3"
            dark
            color="success"
          >
            {{ $t("PICKED_UP") }}
          </v-btn>
          <v-btn
            v-if="selectedOrder.payment_status == 'waiting_for_assembly'"
            @click="acceptOrder()"
            class="mr-3"
            dark
            color="success"
          >
            {{ $t("Acceptance") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <OrderChangeStore
      v-if="orderChangeStoreDialog && selectedOrder.id"
      :item="selectedOrder.id"
      v-model="orderChangeStoreDialog"
      @close="onChangedStore"
    />
  </v-container>
</template>

<style lang="scss">
.w-200 {
  width: 210px;
}

.top-filters {
  overflow-x: auto;
  padding-top: 5px;
}

// @media only screen and (max-width: 1400px) {
//   .top-filters {
//     overflow-x: auto;
//     overflow-y: visible;
//   }
// }

.min_h-90 {
  overflow-y: auto;
  height: 70%;
}

.order-data {
  font-size: 14px;
}

.order-data-title {
  display: inline-block;
  color: black;
}

.order-data-text {
  display: inline-block;
  color: black;
  padding-left: 3px;
  font-weight: 500;
}

.c-black {
  color: black;
}

.theme--dark .c-black,
.theme--dark .order-data-title,
.theme--dark .order-data-text {
  color: white;
}

.dt-max {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mw-200px {
  max-width: 200px !important;
}

.button-gr {
  cursor: default !important;
}

.button-gr::after,
.button-gr::before {
  content: none;
}
</style>
