<script>
import { mask } from "../service/input_mask.js";
import LogoutAlert from "../components/Modals/LogoutAlert.vue";
import YandexMap from "../components/YandexMap.vue";
import { debounce } from "lodash";

export default {
  components: {
    LogoutAlert,
    YandexMap,
  },

  data: () => ({
    isLogoutAlert: false,
    radios: null,
    shippingTimes: [],
    mapHeight: 250,
  }),

  watch: {
    "$i18n.locale"(v) {
      this.$bStore.setLocal("lang", v);
    },
    "orderToAdd.phone"() {
      this.filterUser();
    },
  },

  computed: {
    orderToAdd: {
      get: (vm) => vm.$store.getters.getOrderToAdd,
      set(v) {
        this.$store.dispatch("setOrderToAdd", v);
      },
    },

    newMessageNotifis: (vm) => vm.$store.getters.getNewMessageNotifis,

    user: (vm) => vm.$store.getters.getUser,

    getFullName() {
      let name = this.user.user.first_name;
      let lastName = this.user.user.last_name;

      if (window.outerWidth < 840) return null;
      if (!name?.trim()) return null;

      return `${name}${lastName ? ` ${lastName}` : ""}`;
    },
  },

  created() {
    this.axios.get("v1/order-options?lan=" + this.$i18n.locale).then((res) => {
      this.shippingTimes = Array.isArray(res.data.shipping_times)
        ? res.data.shipping_times
        : [];
    });
  },

  async mounted() {
    if (this.orderToAdd.drugs.length == 0) {
      this.$router.go(-1);
    }

    await this.filterUser();

    let checkOrder = {
      products: this.orderToAdd.drugs.map((x) => ({
        drug_id: x.drug,
        qty: x.qty,
      })),
    };

    this.axios
      .post("v1/check-shipping-error?lang=" + this.$i18n.locale, checkOrder)
      .then((res) => {
        if (res.data.error) {
          let error = res.data;
          let message = error.msg;
          if (Array.isArray(error.errors) && error.errors.length) {
            message += '<div class="pt-2"/>';
            message += this.orderToAdd.drugs
              .map((x) => {
                let err = error.errors.find((err) => err.drug_id == x.drug);
                if (!err) return "";
                return `<div class="pt-2">${x._name} — ${err.msg}</div`;
              })
              .join("<br/>");
          }

          this.$store.dispatch("setSnkMsg", { text: message });
          this.$router.go(-1);
          return;
        }
      });
    this.onResize();
    mask.tel();
  },

  methods: {
    onThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$bStore.setLocal("$vuetify.theme.dark", this.$vuetify.theme.dark);
    },

    getIsCoords() {
      let [longitude, latitude] = this.orderToAdd.location
        ? this.orderToAdd.location.toString().split(",") || []
        : [];

      return Number(longitude) > 0 && Number(latitude) > 0;
    },

    getCoords() {
      const defaultCoords = [41.311151, 69.279737];

      if (this.orderToAdd && this.orderToAdd.location) {
        const [latitude, longitude] = this.orderToAdd.location
          .toString()
          .split(",")
          .map(Number);

        if (!isNaN(latitude) && !isNaN(longitude)) {
          const coords = [latitude, longitude];

          if (JSON.stringify(coords) !== JSON.stringify(defaultCoords)) {
            this.fetchAddress(coords);
          }

          return coords;
        }
      }

      return defaultCoords;
    },

    fetchAddress: debounce(async function (location = this.coords) {
      try {
        let [longitude, latitude] = location;
        this.orderToAdd.location = `${longitude},${latitude}`;
        this.updateOrder();
        const response = await this.getYandexGeocode(location);
        const data = response.data.results;
        let fullAddress = "";
        if (data.street) fullAddress += data.street;
        if (data.city) fullAddress += " " + data.city;
        if (data.district) fullAddress += " " + data.district;
        if (data.house) fullAddress += " " + data.house;
        this.orderToAdd.address = fullAddress.trim();
        this.orderToAdd.additional_address.house = data.house;
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    }, 1600),
    getYandexGeocode(location) {
      return this.axios.get("v2/map", {
        params: {
          location: `${location[0]},${location[1]}`,
        },
      });
    },

    onCoords(coords) {
      let [longitude, latitude] = coords;
      this.orderToAdd.location = `${longitude},${latitude}`;
      this.updateOrder();
    },

    updateOrder() {
      this.orderToAdd = { ...this.orderToAdd };
    },
    async filterUser() {
      const phoneNumber = mask.unTel(this.orderToAdd.phone);
      if (phoneNumber.length === 12 && !this.orderToAdd.first_name) {
        try {
          const response = await this.axios.get("v2/users", {
            params: {
              login: phoneNumber,
            },
          });
          this.orderToAdd.first_name = "";
          if (response && response.data && response.data.user) {
            this.orderToAdd.first_name = response.data.user; // Adjust based on actual response structure
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    },

    isAcceptReport() {
      return this.user.user.groups.some(
        (group) => group.name === "manager" || group.name === "store"
      );
    },

    onNext() {
      if (this.$refs.cartForm.validate()) {
        if (this.getIsCoords()) {
          this.$router.push("/cartPayment");
        } else {
          this.$store.dispatch("setSnkMsg", {
            text: this.$t("Indicate the location on the map!"),
          });
        }
      }
    },

    onResize() {
      let h = window.outerHeight;

      this.mapHeight = h > 500 ? h - 265 : h;
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? '' : 'primary'"
      elevation="3"
      app
      clipped-left
      dark
    >
      <router-link
        tag="span"
        to="/home"
        class="c-pointer title d-flex ml-3 mr-10"
      >
        <img src="/img/icons/favicon-32x32.png" alt="s" />
        <span class="pl-3 logo-name">GoPharm</span>
      </router-link>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
      <div @click="$router.replace('/searchDrugs')" class="py-1">
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndUp"
          rounded
          readonly
          append-icon="mdi-magnify"
          flat
          dense
          hide-details
          :label="$t('Search for drugs...')"
          solo-inverted
        />
        <v-btn v-else dark icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-btn to="/ordersStore" text>
        {{ $t("Orders") }}
      </v-btn>
      <v-btn to="/report" v-if="isAcceptReport()" text>
        {{ $t("Report") }}
      </v-btn>
      <v-badge
        :content="newMessageNotifis.length"
        :value="newMessageNotifis.length"
        color="red"
        offset-x="20"
        offset-y="20"
        overlap
      >
        <v-btn to="/chat" icon class="ml-2">
          <v-icon> mdi-email </v-icon>
        </v-btn>
      </v-badge>
      <v-menu min-width="260">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :icon="!getFullName"
            :color="getFullName ? 'transparent' : ''"
            v-bind="attrs"
            v-on="menu"
            class="ml-2"
          >
            <v-icon>mdi-account-cog</v-icon>
            <template v-if="getFullName">
              <span class="pl-2" v-text="getFullName" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.theme.dark">
            <v-list-item @click="onThemeChange" :value="true">
              <v-list-item-avatar>
                <v-icon v-if="$vuetify.theme.dark">
                  mdi-white-balance-sunny
                </v-icon>
                <v-icon v-else> mdi-weather-night </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("darkMode") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-subheader>{{ $t("languageOfApp") }}</v-subheader>
          <v-list-item-group v-model="$i18n.locale">
            <v-list-item value="uz">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/uz.png" />
              </v-list-item-avatar>
              <v-list-item-title>O'zbek tili</v-list-item-title>
            </v-list-item>
            <v-list-item value="ru">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/ru.png" />
              </v-list-item-avatar>
              <v-list-item-title>Русский язык</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item @click="isLogoutAlert = true">
            <v-list-item-avatar>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <LogoutAlert v-model="isLogoutAlert" />

    <v-main style="padding-top: 85px" @resize="onResize">
      <v-container fluid>
        <v-form ref="cartForm" onsubmit="return false;">
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <v-card>
                <v-card-title>
                  {{ $t("To whom") }}
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.first_name"
                    :label="$t('FIO')"
                    name="FIO"
                    required
                    :rules="[$valid.req]"
                  />
                  <v-text-field
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.phone"
                    @change="filterUser()"
                    autocomplete="off"
                    placeholder="+998 __ ___-__-__"
                    type="tel"
                    :label="$t('Telephone')"
                    name="phone"
                    required
                    :rules="[$valid.reqPhone]"
                  />
                  <v-textarea
                    name="address"
                    outlined
                    dense
                    required
                    :rules="[$valid.req]"
                    @blur="updateOrder"
                    v-model="orderToAdd.address"
                    rows="3"
                    :label="$t('Address')"
                  />
                  <v-text-field
                    name="house"
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.additional_address.house"
                    rows="2"
                    :label="$t('HomeNumber')"
                  />
                  <v-text-field
                    name="apartment"
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.additional_address.apartment"
                    rows="2"
                    :label="$t('Apartment')"
                  />
                  <v-text-field
                    name="entranse"
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.additional_address.entranse"
                    rows="2"
                    :label="$t('Entrance')"
                  />
                  <v-text-field
                    name="floor"
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.additional_address.floor"
                    rows="2"
                    :label="$t('Floor')"
                  />
                  <v-text-field
                    name="intercom"
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.additional_address.intercom"
                    rows="2"
                    :label="$t('Intercom')"
                  />
                  <v-text-field
                    name="landmark"
                    outlined
                    dense
                    @blur="updateOrder"
                    v-model="orderToAdd.additional_address.landmark"
                    rows="2"
                    :label="
                      $t('Additionally: (guideline, comments on the order)')
                    "
                  />
                  <v-radio-group
                    name="shipping_time"
                    required
                    :rules="[$valid.req]"
                    @change="updateOrder"
                    v-if="shippingTimes.length"
                    :label="$t('When?')"
                    v-model="orderToAdd.shipping_time"
                    mandatory
                  >
                    <v-radio
                      v-for="(item, key) in shippingTimes"
                      :key="key"
                      :label="item.name"
                      :value="item.id"
                    />
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <div class="w-100">
                    <v-btn @click="onNext" dark color="primary" block>
                      {{ $t("Next") }}
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="8" md="9">
              <v-card>
                <!-- <v-card-title>
                  {{ $t("Where?") }}
                </v-card-title> -->
                <v-card-text>
                  <div
                    class="pb-1 w-100"
                    :style="{ height: `${mapHeight + 50}px` }"
                  >
                    <YandexMap
                      markerId="address"
                      :isMarker="getIsCoords()"
                      @coords="onCoords"
                      v-model="coords"
                      marker-id="address"
                      @input="fetchAddress"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-main>
  </div>
</template>

<style>
.theme--light .card-text {
  color: black;
}

@media only screen and (max-width: 410px) {
  .logo-name {
    display: none;
  }
}

@media only screen and (min-width: 1221px) {
  .ymaps-2-1-77-searchbox-input__input {
    width: 500px !important;
  }
}
</style>
